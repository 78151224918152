import * as React from 'react'
import { LayoutBootstrap } from '../components/layoutBootstrap'
import { Container, Row, Col } from 'react-bootstrap'
import "../styles/styles.css"
import { StaticImage } from 'gatsby-plugin-image'
import { LanguageContext } from '../components/language'
import { T } from '../translation/translate'


const ContactPage = () => {
    const {lang, setLang} = React.useContext(LanguageContext);

return(
    <LayoutBootstrap>
        <Container fluid style={{backgroundColor:'#6ba7cf'}}>
            <Row className="no-gutters">
                <Col style={{padding:0}}>
                    <StaticImage src={"../images/pages/contact.png"} className="image" alt="study"/>
                </Col>
            </Row>
        </Container>

        <Container fluid className="oddcontainer">
                        {/* <h1 className='bodytext'>Huidige bestuur</h1> */}
                        <Row className='oddcontainer'><Col>
                        <p>{T(lang, {en:"Are you with a company that is interested in working together with InterSECtion? Are you a (potential) SEC student that wants to know more about the SEC programme or an InterSECtion activity? Or do you have any other question to us? Feel free to contact us!",
                                 nl:"Is je bedrijf geïnteresseerd in samenwerken met InterSECtion? Ben je een (potentiële) SEC-student die meer wil weten over het SEC programma of een InterSECtion activiteit? Of heb je een hele andere vraag voor ons? Wees niet bang om contact met ons op te nemen!"})}
                        </p>
                        </Col></Row>
                        <Row className='oddcontainer'><Col>
                        <h1>Contact</h1>
                        <p>
                            <span style={{fontWeight:'bold'}}>{T(lang, {en:"Board 2021/2022: ",
                                nl:"Bestuur 2021/2022: "})}</span>
                            <span>Annick van der Lingen, Sybe Andringa {T(lang, {en:" and ", nl:" en "})} Kirsten Odenwald, </span>
                            <a className="whitelink" to="mailto:intersection@tudelft.nl">intersection@tudelft.nl</a>.
                        </p>
                        
                                    {/* <div className="grid-container">
                                    <div className="grid-item">Voorzitter:</div>
                                    <div className="grid-item">Annick van der Lingen </div>
                                    <div className="grid-item"></div>
                                    <div className="grid-item">Secretaris:</div>
                                    <div className="grid-item">Sybe Andringa</div>
                                    <div className='grid-item'></div>
                                    <div className='grid-item'>Penningmeester:</div>
                                    <div className='grid-item'>Ylke van der Zwet</div>
                                    <div className='grid-item'></div>
                                    </div> <br/> */}
                        <p>
                            <span style={{fontWeight:'bold'}}>{T(lang, {en: "Physical location: ", nl:"Fysieke locatie: "})}</span>
                            <span>{T(lang, {en: "Building 22, room A014", nl: "Gebouw 22, A014"})}</span>
                        </p>
                        <p>
                            <span style={{fontWeight:'bold'}}>{T(lang,{en:'LinkedIn page: ',nl:"LinkedIn pagina: "})}</span><a className='whitelink' to="https://www.linkedin.com/company/study-association-intersection/">Study Association InterSECtion | LinkedIn</a>
                        </p>
                        </Col></Row>

        </Container>

    </LayoutBootstrap>
)

}

export default ContactPage